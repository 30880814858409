<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-5"
        :style="{ backgroundImage: `url(${backgroundImage})` }"
      >
        <!--begin: Aside Container -->
        <div class="d-flex flex-row-fluid flex-column justify-content-between">
          <!--          begin: Aside header -->
          <!--          <a href="#" class="flex-column-auto">-->
          <!--            <h1 class="text-center text-white">LOGO</h1>-->
          <!--          </a>-->
          <!--          end: Aside header -->
          <!--begin: Aside content -->
          <div
            class="flex-column-fluid d-flex flex-column justify-content-center"
          >
            <h3 class="font-size-h1 text-black hidden-sm-and-down login-title">
              <!--              Admin Panel-->
            </h3>
          </div>
          <!--end: Aside content -->
          <!--begin: Aside footer for desktop -->
          <div
            class="d-none flex-column-auto d-lg-flex justify-content-between mt-15"
          >
            <div class="opacity-70 font-weight-bold text-black">
              <!--              © 2020 Real State Vendor Portal-->
            </div>
          </div>
          <!--end: Aside footer for desktop -->
        </div>
        <!--end: Aside Container -->
      </div>
      <!--begin::Aside-->

      <!--begin::Content-->
      <div
        class="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden"
      >
        <div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
          <router-view></router-view>
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>
<style scoped>
.login-title {
  position: relative;
  top: -100px;
  text-align: center;
}
.st0 {
  fill: url(#SVGID_1_);
}
.st1 {
  fill: url(#SVGID_2_);
}
.st2 {
  fill: url(#SVGID_3_);
}
.st3 {
  fill: url(#SVGID_4_);
}
.st4 {
  fill: url(#SVGID_5_);
}
.st5 {
  fill: url(#SVGID_6_);
}
.st6 {
  fill: url(#SVGID_7_);
}
.st7 {
  fill: url(#SVGID_8_);
}
.st8 {
  fill: #178b78;
}
</style>
<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "auth",
  mounted() {
    // check if current user is authenticated
    if (this.isAuthenticated) {
      this.$router.push({ name: "dashboard" });
    }
  },
  methods: {},
  computed: {
    ...mapGetters(["isAuthenticated"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    backgroundImage() {
      return this.$store.state.auth.adminLoginBg;
    }
  }
};
</script>
